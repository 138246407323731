import getAll from '@/api/categories'

export default {
  namespaced: true,
  state: {
    tags: [],
  },
  getters: {},
  mutations: {},
  actions: {
    getServiceCategories({ commit }) {
      getAll().then(response => {
        console.log(response)
        commit()
      })
        .catch(error => console.log(error))
    },
  },
}
