import Axios from 'axios'

const RESOURCE_NAME = 'get/service/category'

const token = localStorage.getItem('access_token')

export default {
  getAll() {
    return Axios.get('RESOURCE_NAME?', {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  create(data) {
    return Axios.post(RESOURCE_NAME, data)
  },
  get(id) {
    return Axios.get(`${RESOURCE_NAME}/${id}`)
  },
  update(id, data) {
    return Axios.put(`${RESOURCE_NAME}/${id}`, data)
  },
  delete(id) {
    return Axios.delete(`${RESOURCE_NAME}/${id}`)
  },
}
